
import { Component, Vue } from 'vue-property-decorator'
import { UserDetail } from '@/types/personnel'

@Component
export default class PersonnelAdd extends Vue {
  private info: UserDetail | {} = {}

  created () {
    this.getInfo()
  }

  getInfo () {
    this.$axios.get(this.$apis.personnel.personnelDetail, {
      userId: this.$route.params.id
    }).then((res) => {
      this.info = res
    })
  }
}
